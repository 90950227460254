import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import ReferralLink from "./ReferralLink";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import TableContainer from "components/Common/TableContainer";
import { useEffect } from "react";
import { getAffiliates } from "store/affliates/actions";
import moment from "moment";
import classNames from "classnames";
import CurrencyFormat from "components/Common/CurrencyFormat";
import { Link } from "react-router-dom";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

const CellRender = (cell) => {
    return cell.value ? cell.value : '';
};

const DateRender = (cell) => {
    return cell.value ? moment(new Date(cell.value)).format("ll") : '';
};

const StatusRender = ({value}) => {
    return <>
        <span>Purchased</span><i className={classNames({
            "mdi ps-1 pe-2": true,
            "mdi-check-box-outline": value.hasPurchased,
            "mdi-checkbox-blank-outline": !value.hasPurchased
        })}/>
        <span>Survey</span><i className={classNames({
            "mdi ps-1 pe-2": true,
            "mdi-check-box-outline": value.isSurveyComplete,
            "mdi-checkbox-blank-outline": !value.isSurveyComplete
        })}/>
        {value.tasksCount > 0 && <span>Tasks ({value.tasksCompleteCount}/{value.tasksCount})</span>}
    </>
};

const columns = /*useMemo(() =>*/ [
    {
        Header: "Name",
        accessor: "displayName",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
            return <CellRender {...cellProps} />;
        }
    },
    {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
            return <StatusRender {...cellProps} />;
        }
    },
    {
        Header: "Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateRender {...cellProps} />;
        },
      },
];//, []);

const AffiliatesPage = () => {

    document.title = "Affiliates | Vertex Robot Trading";

    const dispatch = useDispatch();

    const { profile, loadingProfile } = useSelector(createSelector((state: any) => state.profile, (profile: any) => ({profile: profile.profile, loadingProfile: profile.loading})));
    const { affiliates, loadingAffiliates } = useSelector(createSelector((state: any) => state.affiliates, (affiliates: any) => ({affiliates: affiliates.affiliates, loadingAffiliates: affiliates.loading})));

    useEffect(() => {
        dispatch(getAffiliates());
    }, []);

    return <div className="page-content">
        <Container fluid>
            <Breadcrumbs title="Affiliates" items={[DASHBOARD_ITEM]} />
            <Row>
                <Col sm="3">
                    <Card>
                        <CardBody>
                            <CardTitle>Balance</CardTitle>
                            <div className="d-flex p-3">
                                <div className="me-auto">
                                    Clicks
                                </div>
                                <div>
                                    {profile?.affiliate?.referralUniqueClicks || 0}
                                </div>
                            </div>
                            <div className="d-flex p-3">
                                <div className="me-auto">
                                    Earnings
                                </div>
                                <div>
                                    <CurrencyFormat value={profile?.affiliate?.earningsTotal} />
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-end">
                                <Link to="/chat/support" className="btn btn-secondary align-end">Request Withdrawel</Link>
                            </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle>Your Referrer</CardTitle>
                            <div className="d-flex p-3">
                                <div className="me-auto">
                                    {profile?.affiliate?.displayName || " - "}
                                </div>
                                <div>
                                    {profile?.affiliate?.ref || " - "}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                Referrals
                            </CardTitle>
                            <p className="pb-2 fs-5">
                            Join our affiliate program and earn <strong><CurrencyFormat value={profile?.affiliate?.commissionAmount || 100} noTrailingZeros={true} /></strong> per sale for every new customer you refer. 
                            <br/>It’s a fantastic way to boost your income while sharing the benefits of our trading robot!
                            </p>
                            <h3 className="text-muted pb-2">Your Affiliate Link</h3>
                            <ReferralLink showLabel={false} shortId={profile?.shortId}/>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <div className="mb-4 h4 card-title">Your Referrals</div>
                            <TableContainer
                                columns={columns}
                                data={affiliates}
                                isPagination={false}
                                customPageSize={4}
                                pagination="pagination pagination-rounded justify-content-end mb-2"
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
}

export default AffiliatesPage;