import { useEffect, useState } from "react";
import { Link } from "react-router-dom"

const ResultPagination = ({onPageChange, enabled = [true, true]}) => {

    const [label, setLabel] = useState();
    const [isPreviousPage, setIsPreviousPage] = useState(enabled[0]);
    const [isNextPage, setIsNextPage] = useState(enabled[1]);     

    const handlePageChange = (nextCount) => {
        const result = onPageChange(nextCount);
        setLabel(result.title);
        setIsNextPage(result.isNextPage);
        setIsPreviousPage(result.isPreviousPage);
    }

    useEffect(() => {
        handlePageChange(0);
    }, []);
    
    return (
        <ul className="justify-content-center pagination pagination-rounded">
          <li className={`page-item ${!isPreviousPage ? "disabled" : ''}`}>
            <Link to="#" className="page-link" onClick={() => handlePageChange(1)}>
              <i className="mdi mdi-chevron-left"></i>
            </Link>
          </li>
          <li className="page-item text-muted fs-5 align-self-center">{label}</li>
          {/* {pageOptions.map((item, key) => (
              <li key={key} className={pageIndex === item ? "page-item active" : "page-item"}>
                <Link to="#" className="page-link" onClick={() => gotoPage(item)}>{item + 1}</Link>
              </li>
          ))} */}
          <li className={`page-item ${!isNextPage ? "disabled" : ''}`}>
            <Link to="#" className="page-link" onClick={() => handlePageChange(-1)}>
              <i className="mdi mdi-chevron-right"></i>
            </Link>
          </li>
        </ul>
    )
}   

export default ResultPagination;