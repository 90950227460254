import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import store from "./store";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import "./i18n";
import { analytics, onRouteChange } from "helpers/analytics_helper";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchInterval: 60 * 60 * 1000, refetchOnWindowFocus: false, staleTime: 60 * 60 * 1000 } },
})

onRouteChange((newRoutePath) => {
  analytics.page();
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.Fragment>
          <BrowserRouter>
            {/* <React.StrictMode> */}
              <App />
            {/* </React.StrictMode> */}
          </BrowserRouter>
        </React.Fragment>
      </QueryClientProvider>
    </Provider>
);

serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
