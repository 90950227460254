import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import CurrencyFormat from 'components/Common/CurrencyFormat'
import PrctFormat from 'components/Common/PrctFormat'
import SpinnerInline from 'components/Common/SpinnerInline'
import ResultPagination from './ResultPagination'
import { currencyFormatter } from 'helpers/util'
import { useCallback, useEffect, useState } from 'react'
import { has } from 'lodash'

const MiniWidget = ({report, data, isLoading = false, onPageChange = null, pageEnabled = [true, true], children = null}) => {


  const onInternalPageChange = useCallback((nextCount) => {
    if (onPageChange) {
      return onPageChange(nextCount);
    }
  }, [data]);

  const [chartOptions, setChartOptions] = useState({
    chart: { sparkline: { enabled: true }, stacked: true },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#f1b44c"],
    fill: {
        type: "gradient",
        gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
        },
    },
    yaxis: {
      max: undefined,
      show: false,
    },
    

    // dataLabels: {
      // enabled: true,
      // enabledOnSeries: undefined,
      // formatter: function (val, opts) {
      //     return "Balance: " + val; 
      // },
      // formatter: dataPointFormatter,
      // style: {
      //   fontSize: '14px',
      //   fontWeight: 'bold',
      //   colors: ['#343a40']
      // },
      // textAnchor: 'start',
      // offsetY: -5,
      // background: {
      //   enabled: false,
      // }
      // background: {
      //   enabled: true,
      //   foreColor: '#fff',
      //   borderRadius: 4,
      //   padding: 4,
      //   // opacity: 0.9,
      //   borderWidth: 1,
      //   borderColor: '#fff'
      // },
    // },

    annotations: {
      yaxis: []
    },
    xaxis: {
        type: 'datetime',
    },
    tooltip: { 
      fixed: { enabled: false }, 
      x: { format: "ddd dd MMM yyyy" }, 
      y: {
        formatter: function (value) {
          return currencyFormatter(value, {significantDigits: 2});
        }
      },
      marker: { show: false },
    },
    // tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
  });

  const formatBalanceEnd = (data) => {
    if (!report.isHideDepositsLabel && (data.depositsTotal !== 0 || data.withdrawsTotal !== 0)) {
      return `${currencyFormatter(data.balanceEnd)} (${currencyFormatter(data.depositsTotal + data.withdrawsTotal)})`;
    } else {
      return currencyFormatter(data.balanceEnd);
    }
  }
  
  useEffect(() => {
    const hasSeries = data && data.series[0].data.length > 0;

    if (!hasSeries) {
      setChartOptions({
        ...chartOptions,
        noData: {
          text: isLoading? "Loading..." : "No Data"
        },
        yaxis: {
          show: false,
          max: undefined
        },
        annotations: {yaxis: []},
      });
    } else {
      setChartOptions({
        ...chartOptions,
        yaxis: {
          show: false,
          min: hasSeries && data.series[0].min,
          max: hasSeries && data.series[0].max + ((data.series[0].max - data.series[0].min) * 0.2),
        },
        xaxis: {
          min: /*undefined,*/data.dateStart,
          max: data.dateEnd,
        },  
        ...(data.forecastCount && {forecastDataPoints: {
          count: data.forecastCount,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        }}),
        annotations : {
  
          points: [...(data.points ? data.points : [])],
  
          yaxis: [
            {
              // x: data.series[0].data[0][0],
              y: data.series[0].data[0][1],
              // marker: {
              //   size: 8,
              // },
              // borderColor: '#f1b44c',
              label: {
                borderColor: '#fff',
                style: {
                  fontSize: "14px",
                  // color: '#f00',
                  // background: '#343a40'
                },
                text: currencyFormatter(data.balanceStart)
              }
            }, 
    
            {
              // x: data.series[0].data[data.series[0].data.length - 1][0],
              y: data.series[0].data[data.series[0].data.length - 1][1],
              borderColor: '#fff',
              // marker: {
              //   size: 8,
              // },
              label: {
                borderColor: '#fff',
                style: {
                  fontSize: "14px",
                  //   color: '#fff',
                  //   background: '#343a40'
                },
                text: formatBalanceEnd(data)
              }
            }
          ]
        }
      })
    }
  }, [data]);


  if (!data) {
    return <Card><CardBody className="py-5"><SpinnerInline/></CardBody></Card>
  }

  return (
      <Card>
        <CardBody>
          

          <div className="d-flex">
            <div className="pe-2">

              <p className="text-muted mb-4 text-nowrap">
                <i
                  className={
                    report.icon +
                    " h2 text-" +
                    report.color +
                    " align-middle mb-0 me-1"
                  }
                />
                {report.title}
              </p>

              <div>
                <h5><CurrencyFormat value={data?.value} isProfitLoss={true} isLoading={isLoading}/></h5>
                <div className="fs-2 text-truncate mb-0">
                  <PrctFormat value={data?.valuePrct} isProfitLoss={true} isLoading={isLoading}/>
                  {/* <i className={report.value > 0 ? "mdi mdi-arrow-up ms-1 text-success" : "mdi mdi-arrow-down ms-1 text-danger"}/> */}
                </div>
              </div>
            </div>
            <div className="align-self-stretch w-100">
              {children}
              {onPageChange && <ResultPagination onPageChange={onInternalPageChange} enabled={pageEnabled}/>}
                  {data?.series && <ReactApexChart
                    options={{...chartOptions}}
                    series={data.series}
                    type="area"
                    // height={40}
                    className="apex-charts"
                  />}                
            </div>
          </div>
        </CardBody>
      </Card>
  )
}

export default MiniWidget

// MiniWidget.propTypes = {
//   reports: PropTypes.array
// }