
import { axiosApi } from "./api_helper";

import { initializeApp, FirebaseApp, FirebaseOptions } from "firebase/app";
import { getDatabase, ref, onValue, onDisconnect, set, remove, onChildChanged } from "firebase/database";

import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, 
  sendPasswordResetEmail, updateProfile, signOut, GoogleAuthProvider, FacebookAuthProvider,
  signInWithPopup, 
  User,
  onIdTokenChanged} from "firebase/auth";

import { getStripePayments } from "../ext-firestore-stripe-payments";
/**
 * https://firebase.google.com/docs/auth/web/manage-users
 * https://firebase.google.com/docs/web/modular-upgrade
 */

class FirebaseAuthBackend {

  constructor(firebaseConfig: FirebaseOptions) {

    if (firebaseConfig) {
      // Initialize Firebase
      app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      onAuthStateChanged(auth, user => {
        this.initPresence(user);
      });

      onIdTokenChanged(auth, user => {
        this.updateAuthToken(user);
      });

      payments = getStripePayments(app, {
        productsCollection: "products",
        customersCollection: "customers",
      });

      axiosApi.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 401) {
            // handle 401 error here
            this.updateAuthToken(auth.currentUser);
          }
          return Promise.reject(error);
        }
      );
    }
  }

  updateAuthToken = (user: User) => {
    if (user) {
      user.getIdToken().then((token: string) => {
        axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token;

      }, (error: any) => {
        // JWS TODO can but up sign to say is offline
        console.log('Error getting id token', error);
      });
    } else {
      console.log("No user!");
      delete axiosApi.defaults.headers.common["Authorization"];
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email: string, password: string, displayName: string) => {
    const auth = getAuth(app);
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(userCredential => {
            this.editProfile(displayName);
            resolve(userCredential.user);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  getCurrentUser = () => {
    return getAuth(app).currentUser;
  }

  /**
   * Registers the user with given details
   */
  editProfile = (displayName: string) => {
    
    const auth = getAuth(app);

    return new Promise((resolve, reject) => {

      const user = auth.currentUser;
      if (!user) {
        reject(this._handleError({message: "Update failed, network connection issue!"}));
        return;
      }
      updateProfile(user, { displayName })
        .then(
          (result) => {
            resolve({user: auth.currentUser, message: "User details updated."});
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email: string, password: string) => {
    const auth = getAuth(app);

    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
        .then(
          user => {
            resolve(auth.currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email: string) => {
    const auth = getAuth(app);
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    const auth = getAuth(app);
    
    return new Promise((resolve, reject) => {
      this.stopPresence().then(() => {

        signOut(auth)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });

      });
      
    });
  };

  /**
  * Social Login user with given details
  */

  socialLoginUser = async (type: string) => {
    const auth = getAuth(app);
    let provider;
    if (type === "google") {
      provider = new GoogleAuthProvider();
    } else if (type === "facebook") {
      provider = new FacebookAuthProvider();
    }
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  // addNewUserToFirestore = (user: User) => {
  //   const firestore = getFirestore(app);
  //   const auth = getAuth(app);
  //   const col = collection(firestore, "users");
  //   const profile : UserInfo = user.providerData[0];
  //   const details = {
  //     fullName: profile.displayName,
  //     email: profile.email,
  //     photoUrl: profile.photoURL,
  //     createdDtm: serverTimestamp(),
  //     lastLoginTime: serverTimestamp()
  //   };
  //   setDoc(doc(col, auth.currentUser.uid), details);
  //   return { user, details };
  // };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    let errorMessage = error.message;
    if (errorMessage) {
      errorMessage = errorMessage.replace("Firebase: ", "");
    }
    return errorMessage;
  }

  onUserStatus(callback: any) : any {
    const auth = getAuth(app);
    if (!auth?.currentUser?.uid) {
      console.error("Cannot listen to realtime data no user.");
      return;
    }
    const db = getDatabase(app);
    const docRef = ref(db, "status");
    return onChildChanged(docRef, callback);
  }

  onRealtimeData(dbRef: any, callback: any) : any {
    const auth = getAuth(app);
    if (!auth?.currentUser?.uid) {
      console.error("Cannot listen to realtime data no user.");
      return;
    }
    const db = getDatabase(app);
    const docRef = ref(db, dbRef + "/" + auth.currentUser.uid);
    return onValue(docRef, callback);
  }

  onRealtimeChildData(dbRef: any, callback: any) : any {
    const auth = getAuth(app);
    if (!auth?.currentUser?.uid) {
      console.error("Cannot listen to realtime data no user.");
      return;
    }
    const db = getDatabase(app);
    const docRef = ref(db, dbRef + "/" + auth.currentUser.uid);
    return onChildChanged(docRef, callback);
  }
  
  presenceInitiated = false;
  presenceUnsubscribe: any = null;
  uid: string = null;

  stopPresence = () => {
    this.presenceUnsubscribe();
    this.presenceInitiated = false;
    this.presenceUnsubscribe = null;
    let userStatusDatabaseRef = ref(getDatabase(app), '/status/' + this.uid);
    this.uid = null;
    return set(userStatusDatabaseRef, {
      status: 'SIGNED-OUT',
      lastModified: new Date().toISOString(),
    });
  }

  initPresence = (user: User) => {

    if (this.presenceInitiated) {
      return;
    }
    if (!user) { // not logged in yet
      return;
    }
    this.presenceInitiated = true;
    // Fetch the current user's ID from Firebase Authentication.
    this.uid = user.uid;

    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    const db = getDatabase(app);
    let userStatusDatabaseRef = ref(db, '/status/' + this.uid);

    // Create a reference to the special '.info/connected' path in 
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    this.presenceUnsubscribe = onValue(ref(db, '.info/connected'), (snapshot) => {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            return;
        };

        // If we are currently connected, then use the 'onDisconnect()' 
        // method to add a set which will only trigger once this 
        // client has disconnected by closing the app, 
        // losing internet, or any other means.
        onDisconnect(userStatusDatabaseRef).set({
          status: 'OFF-LINE',
          lastModified: new Date().toISOString(),
        }).then(function() {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect() 
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/getDatabase.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            set(userStatusDatabaseRef, {
              status: 'ON-LINE',
              lastModified: new Date().toISOString()
            });
        });
    });

  }

}

let _fireBaseBackend: FirebaseAuthBackend = null;
let payments = null;
let app : FirebaseApp = null;
/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config: FirebaseOptions) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

const getPayments = () => {
  return getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
  });
  // return payments;
}

const getFirebaseApp = () => {
  return app;
}

export { initFirebaseBackend, getFirebaseBackend, getPayments, getFirebaseApp };
