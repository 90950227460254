import { useQuery } from "@tanstack/react-query";
import { get, post } from "./api_helper";

export const getResult = (id: string) => get(`/trade/list/${id}`);

export const getResults = (id: string, granularity: string, query: any) => get(`/trade/results/${granularity}/${id}`, {params: query});

export const getTradeInfo = (id: string) => get(`/trade/info/${id}`);

export const getForecast = (id: string) => get(`/trade/forecast/${id}`);
