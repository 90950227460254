import {
  LIST_BOTS,
  LIST_BOTS_FAIL,
  LIST_BOTS_SUCCESS,
  GET_BOT,
  GET_BOT_FAIL,
  GET_BOT_SUCCESS,
  NEW_BOT,
  NEW_BOT_SUCCESS,
  ADD_BOT,
  ADD_BOT_SUCCESS,
  ADD_BOT_FAIL,
  UPDATE_BOT,
  UPDATE_BOT_SUCCESS,
  UPDATE_BOT_FAIL,
  DELETE_BOT,
  DELETE_BOT_SUCCESS,
  DELETE_BOT_FAIL,
  UPDATE_BOT_DATA,
} from "./actionTypes"

export const listBots = () => ({
  type: LIST_BOTS,
})

export const listBotsSuccess = bots => ({
  type: LIST_BOTS_SUCCESS,
  payload: bots,
})

export const listBotsFail = error => ({
  type: LIST_BOTS_FAIL,
  payload: error,
})

export const getBot = botId => ({
  type: GET_BOT,
  payload: {botId},
})

export const getBotSuccess = bots => ({
  type: GET_BOT_SUCCESS,
  payload: bots,
})

export const getBotFail = error => ({
  type: GET_BOT_FAIL,
  payload: error,
})

export const newBot = (bot, page, isBack, navigate) => ({
  type: NEW_BOT,
  payload: { bot, page, isBack, navigate },
})

export const newBotSuccess = bot => ({
  type: NEW_BOT_SUCCESS,
  payload: bot,
})

export const addBot = () => ({
  type: ADD_BOT,
})

export const addBotSuccess = bot => ({
  type: ADD_BOT_SUCCESS,
  payload: bot,
})

export const addBotFail = error => ({
  type: ADD_BOT_FAIL,
  payload: error,
})

export const updateBot = (action, bot, data) => ({
  type: UPDATE_BOT,
  payload: {action, bot, data},
})

export const updateBotSuccess = bot => ({
  type: UPDATE_BOT_SUCCESS,
  payload: bot,
})

export const updateBotFail = error => ({
  type: UPDATE_BOT_FAIL,
  payload: error,
})

export const deleteBot = bot => ({
  type: DELETE_BOT,
  payload: bot,
})

export const deleteBotSuccess = bot => ({
  type: DELETE_BOT_SUCCESS,
  payload: bot,
})

export const deleteBotFail = error => ({
  type: DELETE_BOT_FAIL,
  payload: error,
})

export const updateBotData = bot => ({
  type: UPDATE_BOT_DATA,
  payload: bot,
})