import { Spinner, Table } from "reactstrap";
import SpinnerInline from "./SpinnerInline";

const TableInfo = ({data, isWrap = true}) => {

    if (!data || data.length == 0) {
        return <SpinnerInline className="py-5"/>
    }

    return (
        <div className="table-responsive">
            <Table className={`${isWrap ? "" : "table-nowrap"} mb-0`}>
                <tbody>
                {data.map(item => 
                <tr key={`${data.id}-${item.id}`}>
                    <th scope="row">{item.name}</th>
                    <td>{item.value || item.component}</td>
                </tr>
                )}
                </tbody>
            </Table>
        </div>
    )
}

export default TableInfo;