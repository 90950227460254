import {

  LIST_BROKER_SERVERS,
  LIST_BROKER_SERVERS_FAIL,
  LIST_BROKER_SERVERS_SUCCESS,
  LIST_BROKERS,
  LIST_BROKERS_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  brokerServers: null,
  brokers: null,
  loading: true,
  error: null
};

const Broker = (state = INIT_STATE, action) => {
  // console.log("reducer", action.type, "payload: ", action?.payload, "state: ", state);
  switch (action.type) {

    case LIST_BROKER_SERVERS:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LIST_BROKER_SERVERS_SUCCESS:
      return {
        ...state,
        brokerServers: action.payload,
        loading: false,
        error: null
      };

    case LIST_BROKER_SERVERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LIST_BROKERS:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LIST_BROKERS_SUCCESS:
      return {
        ...state,
        brokers: action.payload,
        loading: false,
        error: null
      };

    case LIST_BROKER_SERVERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };


    default:
      return state;
  }
};

export default Broker;
