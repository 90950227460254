import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getBot } from "store/actions";
import Breadcrumbs, { DASHBOARD_ITEM, MY_ROBOTS_ITEM } from "components/Common/Breadcrumb";
import MiniWidget from "./MiniWidget";
import TableInfo from "components/Common/TableInfo";
import BotStatus from "pages/BotTrader/Bots/BotStatus";
import BotIcon from "pages/BotTrader/Bots/BotIcon";
import DateFromNow from "components/Common/DateFromNow";
import { emptyData, getDateRange, toChartData } from "./ResultsHelper";
import PlaceHolder from "components/Common/PlaceHolder";
import CardResult from "./CardResult";
import { getForecast, getResults, getTradeInfo } from "helpers/results_api";
import { useQuery } from "@tanstack/react-query";
import AdminUserProfileView from "pages/User/AdminUserProfileView";

const ResultPage = () => {

    const {id} = useParams();
    const dispatch = useDispatch();

    const { bot, loadingBot } = useSelector(createSelector((state: any) => state.bots, (bots: any) => ({bot: bots.bot, loadingBot: bots.loading})));
    const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));
    const { contacts } = useSelector(createSelector((state: any) => state.chats, (chats: any) => ({contacts: chats.contacts})));
  
    const [botInfo, setBotInfo] = useState(null);
    const [weekData, setWeekData] = useState(null);
    const [weeksAgo, setWeeksAgo] = useState(0);

    const [monthData, setMonthData] = useState(null);
    const [monthsAgo, setMonthsAgo] = useState(0);
    const [forecastData, setForecastData] = useState(null); 
    const [weeklyDateRange, setWeeklyDateRange] = useState(() => getDateRange(0, "week"));
    const [monthlyDateRange, setMonthlyDateRange] = useState(() => getDateRange(0, "month"));
    

    const [options, setOptions] = useState({isForecastCompound: true});


    const [showUserProfile, setShowUserProfile] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);


    const onShowInfo = (bot: any) => {
        setSelectedUser(contacts[bot.userId]);
        setShowUserProfile(true);
      }

    const clearData = () => {
        setWeekData(null);
        setMonthData(null);
        setForecastData(null);
        setBotInfo(null);
    }

    const hasChangedId = () => {
        return id != bot?.id;
    }

    useEffect(() => {
        if (hasChangedId()) {
            clearData();
            dispatch(getBot(id));
        }
    }, [id]);

    const weeklyResult = useQuery({
        queryKey: ['results', id, "hour", weeklyDateRange],
        queryFn: () => getResults(id, "hour", weeklyDateRange),
    });

    const monthlyResult = useQuery({
        queryKey: ['results', id, "day", monthlyDateRange],
        queryFn: () => getResults(id, "day", monthlyDateRange),
    });

    const forecastResult = useQuery({
        queryKey: ['forecast', id],
        queryFn: () => getForecast(id),
    });

    const tradeInfoResult = useQuery({
        queryKey: ['results', id],
        queryFn: () => getTradeInfo(id),
    });

    useEffect(() => {
        if (weeklyResult.data) {
            setWeekData(toChartData("Profit", weeklyDateRange, weeklyResult.data));
        } else {
            setWeekData(emptyData());
        }
    }, [weeklyResult.data]);

    useEffect(() => {
        setWeeklyDateRange(getDateRange(weeksAgo, "week"));
    }, [weeksAgo]);

    useEffect(() => {
        if (monthlyResult.data) {
            setMonthData(toChartData("Profit", monthlyDateRange, monthlyResult.data));
        } else {
            setMonthData(emptyData());
        }
    }, [monthlyResult.data]);

    useEffect(() => {
        setMonthlyDateRange(getDateRange(monthsAgo, "month"));
    }, [monthsAgo]);

    useEffect(() => {
        if (forecastResult.data) {
            const forecastChartData = toChartData("Profit", null, forecastResult.data, forecastResult.data.forecastCount);
            setForecastData(forecastChartData);
        } else {
            setForecastData(emptyData());
        }
        
    }, [forecastResult.data]);

    useEffect(() => {
        if (bot && currentUser) {
            setBotInfo([
                {
                    id: "server",
                    name: "Broker Server",
                    value: bot.server,
                },
                {
                    id: "accountNumber",
                    name: "Account #",
                    value: bot.loginFull || bot.login,
                },
                {
                    id: "eaName",
                    name: "Robot",
                    value: bot.eaName
                },
                {
                    id: "symbol",
                    name: "Symbol",
                    value: `${bot.symbol} (${bot.period})`
                },
                {
                    id: "status",
                    name: "Status",
                    component: <BotStatus bot={bot}/>
                },
                {
                    id: "runtime",
                    name: "Runtime",
                    component: <DateFromNow dateTime={bot.createdAt}/>
                },

                {
                    id: "lastUpdate",
                    name: "Last Update",
                    component: <DateFromNow dateTime={bot.lastModified}/>
                },

                ...(bot.instanceId ? [{
                    id: "instanceId",
                    name: "Instance ID",
                    value: bot.instanceId
                }]: []),

                ...(bot.tradeInfo?.robotVersion ? [{
                    id: "versionId",
                    name: "Robot Version",
                    value: bot.tradeInfo?.robotVersion
                }]: []),

                ...(currentUser?.claims?.admin ? [{
                    id: "user",
                    name: "User",
                    component: <p className="text-muted">
                        <Link to="#" onClick={() => onShowInfo(bot)}>
                            {contacts[bot.userId]?.displayName || bot.userId}
                        </Link>
                    </p>
                }] : [])
            ]);
            
        }
    }, [bot, currentUser]);
        
    return <div className="page-content">
        <Container fluid>
            <Breadcrumbs title="Results" items={[DASHBOARD_ITEM, MY_ROBOTS_ITEM]} />
            {currentUser?.claims?.admin && <AdminUserProfileView user={selectedUser} show={showUserProfile} toggleOpen={() => setShowUserProfile(!showUserProfile)}/>}
        
            <Row>
                {/* <div
                    className="xform-check xform-switch mb-3">
                    <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitch1"
                    checked={compound.checked}
                    onChange={handleIsCompound}
                    />
                    <label
                    className="form-check-label"
                    htmlFor="customSwitch1"

                    >
                    Default switch checkbox input
                    </label>
                </div> */}

                <Col lg="3">
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-content-center pt-2 pb-3">
                                <BotIcon bot={bot}/>
                                <p className="text-muted p-3"><PlaceHolder isLoading={loadingBot} text={bot?.description}/></p>
                            </div>
                            <TableInfo data={botInfo} isWrap={true}/>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="9">
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-stretch gap-3 flex-wrap">
                                <CardResult className="flex-grow-1" title="Balance" value={tradeInfoResult.data?.balance} isLoading={tradeInfoResult.isPending}/>

                                <CardResult className="flex-grow-1" title="Deposits" value={tradeInfoResult.data?.deposits} isLoading={tradeInfoResult.isPending}/>

                                <CardResult className="flex-grow-1" title="Withdrawals" value={tradeInfoResult.data?.withdrawels} isLoading={tradeInfoResult.isPending}/>
                                <CardResult className="flex-grow-1" title="Total Profit" value={tradeInfoResult.data?.profitTotal} isProfitLoss={true} prctValue={tradeInfoResult.data?.profitTotalPrct} isLoading={tradeInfoResult.isPending}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <MiniWidget data={weekData} 
                                report={{
                                    title: "Profit Weekly",
                                    icon: "mdi mdi-calendar-week",
                                    color: "warning",
                                }} 
                                onPageChange={(nextCount) => {
                                    const newWeeksAgo = Math.max(0, weeksAgo + nextCount);
                                    setWeeksAgo(newWeeksAgo);
                                    return {
                                        title: newWeeksAgo > 1 ?  `${newWeeksAgo} weeks ago` : (newWeeksAgo === 0 ? "This week" : "Last week"),
                                        isNextPage: newWeeksAgo > 0,
                                        isPreviousPage: true,
                                    }
                                }} 
                                pageEnabled={[true, false]}
                                isLoading={weeklyResult.isPending}/>
                        </Col>
                        <Col sm="6">
                            <MiniWidget data={monthData} 
                                report={{
                                    title: "Profit Monthly",
                                    icon: "mdi mdi-calendar-month",
                                    color: "warning",
                                }} 
                                onPageChange={(nextCount) => {
                                    const newMonthsAgo = Math.max(0, monthsAgo + nextCount);
                                    setMonthsAgo(newMonthsAgo);
                                    return {
                                        title: newMonthsAgo > 1 ?  `${newMonthsAgo} months ago` : (newMonthsAgo === 0 ? "This month" : "Last month"),
                                        isNextPage: newMonthsAgo > 0,
                                        isPreviousPage: true,
                                    }
                                }} 
                                pageEnabled={[true, false]}
                                isLoading={monthlyResult.isPending}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MiniWidget data={forecastData} 
                                report={{
                                    title: "12 Month Forecast",
                                    icon: "mdi mdi-calendar-month",
                                    color: "warning",
                                    isHideDepositsLabel: true,
                                }} 
                                isLoading={forecastResult.isPending}
                            >
                                
                            </MiniWidget>

                            <div className="d-flex justify-content-center">
                                    {/* <label className="col-form-label me-2 text-muted" htmlFor="isCompound">
                                        Compound {compound ? "check" : "none"}
                                    </label> */}
                                    {/* <input type="checkbox"
                                        name="compound"
                                        id="isCompound"
                                        checked={compound}
                                        disabled={false}
                                        onChange={() => setCompound(!compound)}
                                    /> */}
                                </div>

                                

                        
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>


}

export default ResultPage;