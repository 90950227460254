import {
  LIST_BOTS,
  LIST_BOTS_FAIL,
  LIST_BOTS_SUCCESS,
  GET_BOT,
  GET_BOT_FAIL,
  GET_BOT_SUCCESS,
  NEW_BOT_SUCCESS,
  ADD_BOT,
  ADD_BOT_SUCCESS,
  ADD_BOT_FAIL,
  UPDATE_BOT,
  UPDATE_BOT_SUCCESS,
  UPDATE_BOT_FAIL,
  DELETE_BOT,
  DELETE_BOT_SUCCESS,
  DELETE_BOT_FAIL,
  UPDATE_BOT_DATA
} from "./actionTypes";

const INIT_STATE = {
  bots: [],
  error: {},
  loading: true,
  creating: false, // New bot is in progress of being created,
  bot: null,
  newBot: { 
    // eaId: "stag-1",
    // name: "test",
    // description: "blar blar blar",
    // server: {label: "value", value: "FBS-Demo"},
    // login: "xxx",
    // password: "yyyyyyy",
    // dryRun: true
    eaId: "",
    name: null,
    description: null,
    isDownload: false,
    server: null,
    broker: null,
    login: "",
    password: "",
    dryRun: false
  }, // New bot
  newBotResponse: {
    id: "",
    message: ""
  }
};

const BotTrader = (state = INIT_STATE, action) => {
  // console.log("reducer", action.type, "payload: ", action?.payload, "state: ", state);
  switch (action.type) {

    case LIST_BOTS:
      return {
        ...state,
        loading: true
      };

    case LIST_BOTS_SUCCESS:
      return {
        ...state,
        bots: action.payload,
        loading: false
      };

    case LIST_BOTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_BOT:
      return {
        ...state,
        bot: null,
        loading: true
      };

    case GET_BOT_SUCCESS:
      return {
        ...state,
        bot: action.payload,
        loading: false
      };

    case GET_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      };


    case NEW_BOT_SUCCESS:
      return {
        ...state,
        newBot: {...state.newBot, ...action.payload},
        newBotResponse: {}
      };

    case ADD_BOT:
      return {
        ...state,
        creating: true
      };

    case ADD_BOT_SUCCESS:
      return {
        ...state,
        // bots: [...state.bots, action.payload],
        newBotResponse: {...action.payload},
        newBot: {...INIT_STATE.newBot},
        creating: false
      };

    case ADD_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
        creating: false
      };

    case UPDATE_BOT:
      return {
        ...state,
        bots: state.bots.map(bot =>
          bot.id == action.payload.bot.id
            ? { ...bot, ...{state: "LOADING", errorCode: ""} }
            : bot
        ),
      };

    case UPDATE_BOT_SUCCESS:
      return {
        ...state,
        bots: state.bots.map(bot =>
          bot.id == action.payload.id
            ? { ...bot, ...action.payload }
            : bot
        ),
      };

    case UPDATE_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_BOT:
      return {
        ...state,
        loading: true
      };

    case DELETE_BOT_SUCCESS:
      return {
        ...state,
        loading: false,
        bots: state.bots.filter(
          bot => bot.id !== action.payload
        ),
      };

    case DELETE_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_BOT_DATA:
      return {
        ...state,
        bots: state.bots.map(bot => {
          const botUpdate = action.payload;
          return bot.id == botUpdate.id
            ? { ...bot, ...botUpdate }
            : bot
      }),
      };

    default:
      return state;
  }
};

export default BotTrader;
