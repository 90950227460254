
import Spinner from 'components/Common/Spinner';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';

const BotStatus = ({bot, onUpdateLogin}) => {

    const displayState = (bot) => {
        if (bot.errorCode) {
          return "";
        }
        if (isConnecting(bot)) {
          return "";
        }
        if (bot.isDownload) {
          return "LOCAL / DOWNLOADED";
        }
        return bot.state;
    }

    const isConnecting = (bot): boolean => {
        return bot.state == "STARTED" && bot.loggedIn == false;
    }

    return <>
        {bot.state === "LOADING" || bot.state === "REFRESHING" 
            ? <Spinner isButton={true} status={bot.state}/> 
            : displayState(bot) }
            { isConnecting(bot) &&
              <div className="d-flex"><Alert
              color="success"
              className="p-1 pt-2 fade show"
              role="alert">
                <Spinner isButton={true}/> 
                <span className="px-2">Connecting...</span>
              </Alert></div>
            }
            {
              bot.errorCode == "LOGIN_FAILED" 
                &&  
                  <div className="d-flex">
                    <Alert
                        color="danger"
                        className="p-1 fade show"
                        role="alert">
                      <i className="mdi mdi-block-helper me-2"></i>Login to broker failed!
                        {onUpdateLogin && <Button onClick={()=> onUpdateLogin(bot)} color="danger" outline className="ms-2">Update Login</Button>}
                    </Alert>
                  </div>
            }

    </>

}

BotStatus.propTypes = {
    bot: PropTypes.any,
    onUpdateLogin: PropTypes.func
}

export default BotStatus;