import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Col, Container, Row, Card, CardBody, CardTitle, CardText, CardImg,
  Button,
  Alert
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs, { DASHBOARD_ITEM, MY_ROBOTS_ITEM } from "components/Common/Breadcrumb";

import imgStag from '../../../../assets/images/bots/ea-stag-1.png'
import imgStrength from '../../../../assets/images/bots/ea-strength-1.png'

import { newBot } from '../../../../store/bot-trader/actions'
import { useDispatch } from "react-redux";
import { isDownloadEnabled } from "helpers/config";
import { withTranslation } from "react-i18next";

const BOTS = [
  { id: "stag-1", name: "Stag EURUSD", 
    desc: "EURUSD trading robot using trend and hedge.", 
    features: [
      "<strong>30% - 250%</strong> annual (2-8% monthly) return",
      "Run in the cloud 24/7 for free",
      "Our most popular and recomended robot",
      "Over 2 years consistent results"
    ],
    img: imgStag
  },
  { 
    id: "stag-2", name: "Stag GBPUSD", 
    desc: "GBPUSD trading robot using trend and hedge.",     
    features: [
      "<strong>30% - 250%</strong> annual (2-8% monthly) return",
      "Run in the cloud 24/7 for free"
    ],
    img: imgStag 
  },
  { 
    id: "strength-1", name: "Gold Strength", 
    desc: "Our unique RSI trading strategy with drawdown protection.", 
    features: [
      "<strong>80% - 310%</strong> annual (5-10% monthly) return",
      "Run in the cloud 24/7 for free",
      "Highest recent performer"
    ],
    img: imgStrength 
  },  
  { 
    id: "strength-2", name: "Gold Strength Plus+", 
    desc: "Build wealth faster with our most recent performer", 
    features: [
      
      "<strong>80% - 535%</strong> annual (5-15% monthly) return",
      "Run in the cloud 24/7 for free",
      "Places trades more frequently for faster potential returns"
    ],
    img: imgStrength 
  },
];

const ChooseBotPage = (props) => {
  const dispatch = useDispatch();

  document.title = "New Robot | Vertex Robot Trading";

  const onSelection = (bot) => {
    dispatch(newBot(bot, "choose", false, props.router.navigate))
  };

  return(
    <React.Fragment>
        <div className="page-content">
            <Container fluid>

                <Breadcrumbs title="New Robot" items={[DASHBOARD_ITEM, MY_ROBOTS_ITEM]} />

                <Row className="justify-content-center">
                    <Col md="11" xl="9" className="d-flex flex-column xalign-items-center">
                      <div className="step-bar">
                        <div className="step active">
                          <button className="number">1</button>
                          <p>Choose a robot</p>
                        </div>
                        <div className="bar"></div>
                        <div className="step">
                          <button className="number">2</button>
                          <p>Select broker</p>
                        </div>
                        <div className="bar"></div>
                        <div className="step">
                          <button className="number">3</button>
                          <p className="px-3">Finish</p>
                        </div>
                      </div>
                      {isDownloadEnabled && <Alert color="success" className="fs-5 mt-1 mb-4" role="alert">
                                {props.t("Download Alert")}
                            </Alert> }
                      <div className="step-content pb-5">
                        <Row>
                          {BOTS.map(bot => 
                            <Col key={bot.id} xl={6} md={6}>
                              <Card className="mx-lg-2 my-lg-1 mx-xl-4 my-xl-3 hover-select">
                                <CardBody>
                                  <div className="xd-flex">
                                      <img src={bot.img} height="80" className="float-end logo-dark-element"/>
                                      <CardTitle className="fs-4">
                                        {bot.name}
                                      </CardTitle>
                                      <p className="mt-3 fs-4">
                                        {bot.desc}
                                      </p>
                                  </div>
                                  <ul className="ps-2 fs-5 mb-1 bot-features-height">
                                    { bot.features.map((f, index) => <li key={"ftr-" + bot.id + index}  dangerouslySetInnerHTML={{ __html: f }}/>) }
                                  </ul>
                                </CardBody>
                                <Button onClick={() => onSelection({eaId: bot.id, isDownload: false})} color="primary" className="m-2">
                                  <i className="me-2 font-size-16 mdi mdi-run align-middle"/>{" "}
                                  Run
                                </Button>
                                {isDownloadEnabled && <Button color="outline-secondary" onClick={() => onSelection({eaId: bot.id, isDownload: true})} className="m-2">
                                  <i className="me-2 font-size-16 mdi mdi-download align-middle"/>{" "}
                                  Download
                                </Button>}
                              </Card>
                            </Col>
                          )} 

                        </Row>
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
  );
};

ChooseBotPage.propTypes = {
  navigate: PropTypes.object,
};

export default withRouter(withTranslation()(ChooseBotPage));