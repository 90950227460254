import { call, put, takeEvery } from "redux-saga/effects";

// BotTrader Redux States
import {
  LIST_BOTS,
  NEW_BOT,
  GET_BOT,
  DELETE_BOT,
  UPDATE_BOT,
} from "./actionTypes";
import {
  listBotsFail,
  listBotsSuccess,
  getBotFail,
  getBotSuccess,
  newBotSuccess,
  addBot,
  addBotFail,
  addBotSuccess,
  updateBotSuccess,
  updateBotFail,
  deleteBotSuccess,
  deleteBotFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  listBots,
  getBot,
  createBot,
  startBot,
  stopBot,
  deleteBot,
  listBrokerServers,
  listBrokers,
  updateBotLogin,
} from "helpers/bot_trader_api";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* onListBots() {
  try {
    const response = yield call(listBots);
    yield put(listBotsSuccess(response));
  } catch (error) {
    yield put(listBotsFail(error));
  }
}

function* onGetBot({ payload: { botId } }) {
  try {
    const response = yield call(getBot, botId);
    yield put(getBotSuccess(response));
  } catch (error) {
    yield put(getBotFail(error));
  }
}

function* onUpdateBot({ payload: { action, bot, data} }) {
  let actionName = "";
  try {
    let responseData = null;
    switch(action) {
      case "START":
        // actionName = "started";
        responseData = yield call(startBot, bot.id, data);
        break;
      case "STOP":
        actionName = "stopped";
        responseData = yield call(stopBot, bot.id);
        break;
      case "UPDATE_LOGIN":
        // actionName = "updated";
        responseData = yield call(updateBotLogin, bot.id, data);
        break;
    }
    yield put(updateBotSuccess({id: bot.id, state: "REFRESHING"}));
    if (!responseData || responseData.success) {
      if (actionName) {
        toast.success(`Robot ${actionName} successfully`);
      }
    } else {
      toast.warn(`Robot ${actionName} unsuccessfully! ${responseData.message}`, {autoClose: false});
    }
    
    yield onListBots();
  } catch (error) {
    yield put(updateBotFail(error));
    toast.error(`Robot ${actionName} failed`);
  }
}

function* onNewBot({ payload: { bot, page, isBack, navigate } }) {
  yield put(newBotSuccess(bot));
  let botId = null;
  if (page == "broker" && !isBack) {
    try {
      yield put(addBot());
      const response = yield call(createBot, {
        dryRun: bot.dryRun,
        name: bot.name, 
        description: bot.description,
        broker: bot.broker?.value, 
        server: bot.server?.value,
        login: bot.login, 
        password: bot.password, 
        eaId: bot.eaId,
        isDownload: bot.isDownload
      });
      if (!response.success) {
        yield put(addBotFail(response.message));
        toast.warn(`Creating robot failed! ${response.message}`, {autoClose: false});
        return;
      }
      botId = response.id;
      yield put(addBotSuccess({...response}));
      // toast.success(`Robot created successfully`);
    } catch (error) {
      yield put(addBotFail(error));
      toast.error(`Creating robot failed`)
      return;
    }
  }

  switch(page) {
    case "choose":
      navigate("/bots/new/broker");
      break
    case "broker":
      isBack ? navigate("/bots/new/choose") : navigate(`/bots/new/finish/${botId}`);
      break
    case "finish":
      isBack ? navigate("/bots/new/broker") : navigate("/bots");
    break
  }
}

function* onDeleteBot({ payload: bot }) {
  try {
    yield call(deleteBot, bot.id);
    yield put(deleteBotSuccess(bot.id));
    toast.success(`Robot deleted successfully`);
    yield onListBots();
  } catch (error) {
    yield put(deleteBotFail(error));
    toast.error(`Deleting robot failed`);
  }
}

// function* onAddBot({ payload: bot }) {
//   try {
//     const response = yield call(createBot, bot);
//     yield put(addBotSuccess(response));
//     toast.success("Robot added successfully");
//   } catch (error) {
//     yield put(addBotFail(error));
//     toast.error("Creating robot failed");
//   }
// }


function* botTraderSaga() {
  yield takeEvery(LIST_BOTS, onListBots);
  yield takeEvery(GET_BOT, onGetBot);
  yield takeEvery(NEW_BOT, onNewBot);
  // yield takeEvery(ADD_BOT, onAddBot);
  yield takeEvery(UPDATE_BOT, onUpdateBot);
  yield takeEvery(DELETE_BOT, onDeleteBot);
}

export default botTraderSaga;
