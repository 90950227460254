

export function getBrowserLocales(options = {}) {

    const defaultOptions = {
        languageCodeOnly: false,
        countryCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,

    };

    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        if (opt.languageCodeOnly) {
            return trimmedLocale.split(/-|_/)[0];
        } else if (opt.countryCodeOnly) {
            return trimmedLocale.split(/-|_/)[1];
        } 
        return trimmedLocale;
    });

}

let locale = null;
export function getLocale() {
    if (locale) {
        return locale;
    }
    const locales = getBrowserLocales();
    if (locales && locales.length > 0) {
        locale = locales[0];
    } else {
        locale = "en";
    }
    return locale;
}

export function getServerError(error) {
    if (error instanceof String || typeof error == "string") {
        return error;
    }
    // error is not a message
    console.error("Error", JSON.stringify(error || {}), typeof error);
    return "Unexpected server error!";
}


export function isNotNull(value: any): boolean {
    return value != null || value != undefined;
}

export function isNull(value: any): boolean {
    return !isNotNull(value);
}

const defaultCurrencyFormatterOptions = {
    significantDigits: 0,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$'
}
  
export function currencyFormatter(value, options?: {significantDigits?: number, thousandsSeparator?: string, decimalSeparator?: string, symbol?: string}) {
    // if (typeof value !== 'number') value = 0.0;
    const {significantDigits, thousandsSeparator, decimalSeparator, symbol} = { ...defaultCurrencyFormatterOptions, ...options }
    const valueStr = value.toFixed(significantDigits);
    const [currency, decimal] = valueStr.split('.');
    return `${symbol}${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandsSeparator
    )}${significantDigits > 0 ? decimalSeparator : ''}${decimal || ''}`;
}