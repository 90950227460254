import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import profile from "./auth/profile/reducer";
import affiliates from "./affliates/reducer";
import results from "./results/reducer";


import bots from "./bot-trader/reducer";

import broker from "./broker/reducer";
import chats from "./chat/reducer";
import { LOGOUT_USER_SUCCESS } from "./auth/login/actionTypes";

const appReducer = combineReducers({
  // public
  bots,
  chats, 
  login,
  profile,
  Layout,
  Account,
  ForgetPassword,
  affiliates, 
  results,
  broker
});

const rootReducer = (state, action) => {
  if (action.type == LOGOUT_USER_SUCCESS) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;
