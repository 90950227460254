
const PlaceHolder = ({isLoading = true, text = "", loadingMessage = "Loading...", minWidth = 50}) => {

    if (isLoading) {
        return <span className="placeholder-glow">
            <span className="placeholder" style={{minWidth}}>{loadingMessage || "&nbsp;"}</span>
        </span>
    }
    return text;
}

export default PlaceHolder;