import { currencyFormatter } from "helpers/util";
import moment, { Moment, unitOfTime } from "moment";

const minBalanceDefault = 10000;

export const forecast = (name: string, balance: number, trades: any[], magicNumbers: string[], weeks: number, isBalance = false): any => {

    let currentDate = moment();
    let startDate = currentDate.clone().startOf("day").subtract(6, "month");
    let endDate = currentDate.clone().endOf("day");


    const {balanceStart, balanceEnd, depositsTotal, withdrawsTotal, dailyProfitAverageByLot, value, valuePrct, series: [series]} = 
        profitByDates(name, balance, trades, magicNumbers, startDate, endDate, "day", minBalanceDefault, isBalance);

    if (series.data.length == 0) { 
        return {value: 0, valuePrct: 0, series: [{data: []}]};
    }
    const profit = value;
    const profitPrct = valuePrct;
    const profitEnd = series.data[series.data.length - 1][1];

    const {amount, data, max} = calcForecast(dailyProfitAverageByLot, profitEnd, balanceEnd, endDate, "day", weeks * 7, minBalanceDefault, isBalance);

    const totalProfit = amount - depositsTotal;
    const totalProfitPrct = balanceStart == 0 ? 0 : totalProfit / balanceStart;

    return {
        balanceStart, 
        balanceEnd: amount, depositsTotal, withdrawsTotal,
        profit, profitPrct,
        value: totalProfit, valuePrct: totalProfitPrct.toFixed(2), 
        forecastCount: data.length,
        points: [{
            x: series.data[series.data.length - 1][0],
            y: series.data[series.data.length - 1][1],
            marker: {
              size: 6,
              strokeColor: "#f1b44c",
            },
            label: {style: {fontSize: "14px"}, offsetY: -4, fontSize: "16px", text: currencyFormatter(balanceEnd)}},
        ],
        series: [
            {name, min: series.min, max, data: [...series.data, ...data]}, 
        ]
    };
}

export const profitWeekly = (name: string, balance: number, trades: any[], magicNumbers: string[], weeksAgo: number): any => {
    return profitBy(name, balance, trades, magicNumbers, weeksAgo, "week", "hour");
}

export const profitMonthly = (name: string, balance: number, trades: any[], magicNumbers: string[], monthsAgo: number): any => {
    return profitBy(name, balance, trades, magicNumbers, monthsAgo, "month", "day");
}

export const profitBy = (name: string, balance: number, trades: any[], magicNumbers: string[], periodAgo: number, period: unitOfTime.Base, granularity: unitOfTime.StartOf): any => {
    let currentDate = moment().subtract(periodAgo, period);
    let start = currentDate.clone().startOf(period);
    let end = currentDate.clone().endOf(period);
    return profitByDates(name, balance, trades, magicNumbers, start, end, granularity);
}

export const profitByDates = (name: string, balance: number, trades: any[], magicNumbers: string[], 
                                dateStart: Moment, dateEnd: Moment, granularity: unitOfTime.StartOf, minBalance = minBalanceDefault, isBalance = false): any => {
    let sortedTrades = trades.filter(t => t.isHistory).sort((a, b) => moment(a.octUtc).valueOf() - moment(b.octUtc).valueOf());

    let balances = calcBalances(balance, sortedTrades, dateStart, dateEnd);
    return {...profit(name, filterByDates(sortedTrades, dateStart, dateEnd), magicNumbers, balances, granularity, minBalance, isBalance), 
        dateStart: dateStart.valueOf(),
        dateEnd: dateEnd.valueOf(),
    };
}

export const calcBalances = (balance: number, trades: any[], start: Moment, end: Moment) => {
    let balanceStart = balance;
    let balanceEnd = balance;
    let depositsTotal = 0;
    let withdrawsTotal = 0;
    for (let i = trades.length - 1; i >= 0; i--) {
        if (start && start.isAfter(trades[i].octUtc)) {
            break;
        }
        balanceStart -= trades[i].opl;
        if (end && end.isBefore(trades[i].octUtc)) {
            balanceEnd -= trades[i].opl;
        } else {
            if (trades[i].ot >= 2) {
                if (trades[i].opl > 0) {
                    depositsTotal += trades[i].opl;
                } else {
                    withdrawsTotal += trades[i].opl;
                }
            }
        }

    }
    balanceStart = parseFloat(balanceStart.toFixed(2));
    balanceEnd = parseFloat(balanceEnd.toFixed(2));
    depositsTotal = parseFloat(depositsTotal.toFixed(2));
    withdrawsTotal = parseFloat(withdrawsTotal.toFixed(2));

    if (balanceStart == 0) {
        const deposit = trades.find(t => t.ot >= 2);
        if (deposit) {
            balanceStart = deposit.opl;
        }
    }
    return {balanceStart, balanceEnd, depositsTotal, withdrawsTotal};

}

export const filterByDates = (trades: any[], start: Moment, end: Moment): any => {
    // console.log("length", trades.length, trades[0].ootUtc, trades[trades.length - 1].ootUtc);
    // console.log("ws", start.toISOString());
    // console.log("we", end.toISOString());
    let filtered = [];
    for (let t of trades) {
        if (start && start.isAfter(t.octUtc)) {
            continue;
        }
        if (end && end.isBefore(t.octUtc)) {
            break;
        }
        filtered.push(t);
    };
    return filtered;
}

export const profit = (name: string, trades: any[], magicNumbers: string[], {balanceStart, balanceEnd, depositsTotal, withdrawsTotal}, 
                granularity: unitOfTime.StartOf, minBalance: number, isBalance = false): any => {
    const dataProfit = [];
    let profit = 0;
    let balance = balanceStart;
    let dateTime = 0;
    let max = 0;
    let min = isBalance ? balanceStart : 0;
    // let value = isBalance ? balanceStart : 0;
    let hasTrades = false;
    let hasTradesToday = false;
    let withTradesCount = 0;
    // let lotSize = Math.max(1, Math.floor(balanceStart / minBalance));
    let profitPerLot = 0;
    let profitByUnit = 0;
    trades.forEach((trade, index) => {
        if (magicNumbers.indexOf(trade.omn) >= 0) {
            profit += trade.opl;
            profitByUnit += trade.opl;
            hasTrades = true;
            hasTradesToday = true;
        } else if(trade.omn) {
            // console.log("None robot trade: ", trade);
        }
        if (index > 0) {
            balance += trade.opl;
        }
        let value = isBalance? balance : profit;

        min = Math.min(min, value);
        max = Math.max(max, value);    
        let date = moment(trade.octUtc).startOf(granularity).valueOf();
        if (dateTime !== date || (index == trades.length - 1)) {
            if (hasTradesToday) {
                const lotSize = Math.max(1, Math.floor(balance / minBalance));
                profitPerLot += (profitByUnit / lotSize);
                profitByUnit = 0;
                withTradesCount++;
                hasTradesToday = false;
            }
            dataProfit.push([date, parseFloat((value).toFixed(2))]);
            dateTime = date;
        } 
    });
    const profitPrct = balanceStart == 0 ? 0 : profit / balanceStart;
    // console.log("profitPrct", trades.length, profitPrct, profit.toFixed(2), balanceStart.toFixed(0), balanceEnd.toFixed(0) + "=" + (balanceStart + profit).toFixed(0), depositsTotal.toFixed(0));
    console.log("profitPerLot total", profitPerLot);
    return {balanceStart, balanceEnd, depositsTotal, withdrawsTotal, dailyProfitAverageByLot: profitPerLot / withTradesCount,
            value: profit.toFixed(2), valuePrct: profitPrct, 
            series: [
                {name, min, max, data: hasTrades ? dataProfit : []}, 
            ]};
}

function calcForecast(profitPerLot: number, startProfit: number, startAmount: number, startDate: Moment, period: unitOfTime.Base, 
                        length: number, minBalance: number, isBalance = false): any {
    let lotSize = Math.max(1, Math.floor(startAmount / minBalance));
    // const profitPerLot = profitAmount / lotSize;
    let amount = startAmount;
    let profit = startProfit;
    let max = 0;
    const data = [];
    const date = startDate.clone();
    // console.log("calcForecast profitByUnit", profitPerLot);
    for (let count = 0; count < length && amount >= minBalance / 4; count++) {
        if ([5,6].indexOf(date.weekday()) == -1) {
            lotSize = Math.max(1, Math.floor(amount / minBalance));
            amount += (profitPerLot * lotSize);
            profit += (profitPerLot * lotSize);
            let value = isBalance ? amount : profit;    
            max = Math.max(max, value);
            date.add(1, period);
            data.push([date.valueOf(), parseFloat(value.toFixed(2))]);
        } else {
            date.add(1, period);
        }
    }
    return {amount, data, max};
}

