import { call, put, takeEvery } from "redux-saga/effects";

// BotTrader Redux States
import {
  LIST_BROKER_SERVERS,
  LIST_BROKERS,
} from "./actionTypes";
import {
  listBrokerServersFail,
  listBrokerServersSuccess,
  listBrokersFail,
  listBrokersSuccess
} from "./actions";

//Include Both Helper File with needed methods
import {
  listBrokerServers,
  listBrokers,
} from "helpers/bot_trader_api";

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* onListBrokerServers() {
  try {
    const response = yield call(listBrokerServers);
    yield put(listBrokerServersSuccess(response));
  } catch (error) {
    yield put(listBrokerServersFail(error));
  }
}

function* onListBrokers() {
  try {
    const response = yield call(listBrokers);
    yield put(listBrokersSuccess(response));
  } catch (error) {
    yield put(listBrokersFail(error));
  }
}

function* brokerSaga() {
  yield takeEvery(LIST_BROKER_SERVERS, onListBrokerServers);
  yield takeEvery(LIST_BROKERS, onListBrokers);
}

export default brokerSaga;
