import {
  LIST_BROKER_SERVERS,
  LIST_BROKER_SERVERS_FAIL,
  LIST_BROKER_SERVERS_SUCCESS,
  LIST_BROKERS,
  LIST_BROKERS_FAIL,
  LIST_BROKERS_SUCCESS,
} from "./actionTypes"

export const listBrokerServers = () => ({
  type: LIST_BROKER_SERVERS,
})

export const listBrokerServersSuccess = brokerServers => ({
  type: LIST_BROKER_SERVERS_SUCCESS,
  payload: brokerServers,
})

export const listBrokerServersFail = error => ({
  type: LIST_BROKER_SERVERS_FAIL,
  payload: error,
})

export const listBrokers = () => ({
  type: LIST_BROKERS,
})

export const listBrokersSuccess = brokers => ({
  type: LIST_BROKERS_SUCCESS,
  payload: brokers,
})

export const listBrokersFail = error => ({
  type: LIST_BROKERS_FAIL,
  payload: error,
})
