import { error } from "console";
import { 
  GET_RESULT_SUCCESS,
  GET_RESULT,
  GET_RESULT_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  success: false,
  loading: false,
  result: null,
};

const results = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULT:
      return { ...state, result: null, loading: true, error: null };
    case GET_RESULT_SUCCESS:
      return { ...state, result: action.payload, loading: false, error: null };
    case GET_RESULT_ERROR:
      return { ...state, result: null, loading: false, error: action.payload};
  }
  return state;
};

export default results;
