
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Label, Modal } from "reactstrap";
import { createSelector } from "reselect";
import Select from "react-select";
import { listBrokerServers, updateBot } from "store/actions";

const LoginModal = ({open, setOpen, data}) => {

    const dispatch = useDispatch();

    const [passwordShow, setPasswordShow] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [server, setServer] = useState(null);
    const [servers, setServers] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const {
        brokerServers, brokerLoading
    } = useSelector(createSelector(
        (state: any) => (state.broker),
        (broker: any) => {
            return {
                brokerLoading: broker.loading,
                brokerServers: broker.brokerServers,
            };
        }
    ));

    const toggleOpen = () => {
        setOpen(!open);
    }

    useEffect(() => {
        if (open && !brokerServers) {
            dispatch(listBrokerServers());
        }
    }, []);

    useEffect(() => {
        if (brokerServers) {
            setLogin(data.login);
            const options = brokerServers.filter(b => b.brokerId == data.broker).map(b => ({label: b.name, value: b.server}));
            setServer(options.find(o => o.value == data.server));
            setServers(options);
        }
    }, [brokerServers]);

    const updateLogin = () => {
        if (/\D/.test(login)) {
            setErrorMessage("Please enter numbers only Account ID for your Broker trading account!");
            return;
        }
        dispatch(updateBot("UPDATE_LOGIN", data, {login, password, server: server.value}));
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            toggle={() => {
                toggleOpen()
            }}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Update Broker Login Details</h5>
                <button type="button"
                    onClick={() => {
                        setOpen(false);
                    }} className="btn-close"></button>
            </div>
            <div className="modal-body">
                <form>
                    {errorMessage && <Alert color="danger"
                        className="alert-dismissible fade show"
                        role="alert">
                        <i className="mdi mdi-block-helper me-2"></i>
                        {errorMessage}
                    </Alert>}
            
                    
                    <div className="mb-3">
                        <Label htmlFor="form-login" className="col-form-label">Account ID</Label>
                        <input type="text" className="form-control" name="login" id="form-login" 
                            placeholder="Enter trading account ID number, example: 12345678"
                            onChange={e => setLogin(e.target.value)} value={login} />
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="form-password" className="col-form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                            <input type={passwordShow ? "text" : "password"} className="form-control"
                                placeholder="Enter Password"
                                name="password" id="form-password" onChange={e => setPassword(e.target.value)} value={password} />
                            <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                            </button>
                        </div>
                    </div>

                    <div>
                        <Label htmlFor="form-row-broker-Input" className="col-md-4 col-form-label">Broker</Label>
                        <div className="col-md-8">
                            <Select className="select2-selection"
                                id="form-row-broker-Input"
                                onChange={setServer}
                                value={server}
                                isLoading={brokerLoading}
                                isSearchable={true}
                                options={servers}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button onClick={() => setOpen(false)} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button onClick={() => updateLogin()} type="button" className="btn btn-primary">Update</button>
            </div>
        </Modal>
    )
}

export default LoginModal;