import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getBot, getResult } from "store/actions";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";
import MiniWidget from "./MiniWidget";
import TableInfo from "components/Common/TableInfo";
import BotStatus from "pages/BotTrader/Bots/BotStatus";
import BotIcon from "pages/BotTrader/Bots/BotIcon";
import DateFromNow from "components/Common/DateFromNow";
import { forecast, profitMonthly, profitWeekly } from "./ResultsHelper.v1";
import PlaceHolder from "components/Common/PlaceHolder";
import moment from "moment";
import CurrencyFormat from "components/Common/CurrencyFormat";
import CardResult from "./CardResult";

const ResultPageV1 = () => {

    const dispatch = useDispatch();

    const {id} = useParams();
    const { result, loadingResult } = useSelector(createSelector((state: any) => state.results, (results: any) => ({result: results.result, loadingResult: results.loading})));
    const { bot, loadingBot } = useSelector(createSelector((state: any) => state.bots, (bots: any) => ({bot: bots.bot, loadingBot: bots.loading})));
    const [botInfo, setBotInfo] = useState(null);
    const [weekData, setWeekData] = useState(null);
    const [weeksAgo, setWeeksAgo] = useState(-1);

    const [monthData, setMonthData] = useState(null);
    const [monthsAgo, setMonthsAgo] = useState(-1);
    const [today, setToday] = useState(moment().format("YYYY-MM-DD"));
    const [forecastData, setForecastData] = useState(null); 

    useEffect(() => {
        const interval = setInterval(() => {
            setToday(moment().format("YYYY-MM-DD"));
        }, 24 * 60 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    const clearData = () => {
        setWeekData(null);
        setMonthData(null);
        setForecastData(null);
        setBotInfo(null);
        setWeeksAgo(-1);
        setMonthsAgo(-1);
    }

    const hasChangedId = () => {
        return id != bot?.id;
    }

    useEffect(() => {
        if (hasChangedId()) {
            clearData();
            dispatch(getResult(id));
            dispatch(getBot(id));
        }
    }, [id]);

    useEffect(() => {   
        if (loadingBot || loadingResult) {
            clearData();
        }
    }, [loadingBot, loadingResult]);

    useEffect(() => {
        // if (loadingBot || loadingResult) {
        //     return;
        // }
        if (hasChangedId()) {
            return;
        }
        if (result && bot) {
            setWeeksAgo(0);
            setMonthsAgo(0);
        }
    }, [result, bot]);

    useEffect(() => {
        if (weeksAgo > -1) {
            console.log("res", result);
            setWeekData(profitWeekly("Profit", result.balance, result.trades, [result.magicBuy, result.magicSell], weeksAgo));
        } else {
            setWeekData(null);
        }
    }, [weeksAgo, today]);

    useEffect(() => {
        if (monthsAgo > -1) {
            setMonthData(profitMonthly("Profit", result.balance, result.trades, [result.magicBuy, result.magicSell], monthsAgo));
        } else {
            setMonthData(null);
        }
    }, [monthsAgo, today]);

    useEffect(() => {
        if (hasChangedId()) {
            return;
        }
        if (result && bot) {
            setForecastData(forecast("Profit", result.balance, result.trades, [result.magicBuy, result.magicSell], 52));
        } else {
            setForecastData(null);
        }
    }, [result, bot]);

    useEffect(() => {
        if (bot) {
            setBotInfo([
                {
                    id: "server",
                    name: "Broker Server",
                    value: bot.server,
                },
                {
                    id: "accountNumber",
                    name: "Account #",
                    value: bot.loginFull || bot.login,
                },
                {
                    id: "eaName",
                    name: "Robot",
                    value: bot.eaName
                },
                {
                    id: "symbol",
                    name: "Symbol",
                    value: `${bot.symbol} (${bot.period})`
                },
                {
                    id: "status",
                    name: "Status",
                    component: <BotStatus bot={bot}/>
                },
                {
                    id: "runtime",
                    name: "Runtime",
                    component: <DateFromNow dateTime={bot.createdAt}/>
                },

                {
                    id: "lastUpdate",
                    name: "Last Update",
                    component: <DateFromNow dateTime={bot.lastModified}/>
                },

                ...(bot.instanceId ? [{
                    id: "instanceId",
                    name: "Instance ID",
                    value: bot.instanceId
                }]: [])
            ]);
            
        }
    }, [bot]);
        
    return <div className="page-content">
        <Container fluid>
            <Breadcrumbs title="Results" items={[DASHBOARD_ITEM]} />
            <Row>
                <Col lg="3">
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-content-center pt-2 pb-3">
                                <BotIcon bot={bot}/>
                                <p className="text-muted p-3"><PlaceHolder isLoading={loadingBot} text={bot?.description}/></p>
                            </div>
                            <TableInfo data={botInfo} isWrap={true}/>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="9">
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-stretch gap-3 flex-wrap">
                                <CardResult className="flex-grow-1" title="Balance" value={result?.balance} />

                                <CardResult className="flex-grow-1" title="Deposits" value={forecastData?.depositsTotal} />

                                <CardResult className="flex-grow-1" title="Withdrawals" value={forecastData?.withdrawsTotal} />
                                <CardResult className="flex-grow-1" title="Total Profit" value={forecastData?.profit} isProfitLoss={true} prctValue={forecastData?.profitPrct} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <MiniWidget data={weekData} 
                                report={{
                                    title: "Profit Weekly",
                                    icon: "mdi mdi-calendar-week",
                                    color: "warning",
                                }} 
                                onPageChange={(nextCount) => {
                                    const newWeeksAgo = Math.max(0, weeksAgo + nextCount);
                                    setWeeksAgo(newWeeksAgo);
                                    return {
                                        title: newWeeksAgo > 1 ?  `${newWeeksAgo} weeks ago` : (newWeeksAgo === 0 ? "This week" : "Last week"),
                                        isNextPage: newWeeksAgo > 0,
                                        isPreviousPage: true,
                                    }
                                }} 
                                pageEnabled={[true, false]}/>
                        </Col>
                        <Col sm="6">
                            <MiniWidget data={monthData} 
                                report={{
                                    title: "Profit Monthly",
                                    icon: "mdi mdi-calendar-month",
                                    color: "warning",
                                }} 
                                onPageChange={(nextCount) => {
                                    const newMonthsAgo = Math.max(0, monthsAgo + nextCount);
                                    setMonthsAgo(newMonthsAgo);
                                    return {
                                        title: newMonthsAgo > 1 ?  `${newMonthsAgo} months ago` : (newMonthsAgo === 0 ? "This month" : "Last month"),
                                        isNextPage: newMonthsAgo > 0,
                                        isPreviousPage: true,
                                    }
                                }} 
                                pageEnabled={[true, false]}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MiniWidget data={forecastData} 
                                report={{
                                    title: "12 Month Forecast",
                                    icon: "mdi mdi-calendar-month",
                                    color: "warning",
                                    isHideDepositsLabel: true,
                                }} 
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>


}

export default ResultPageV1;